// global.$ = global.jQuery = require('jquery');
require('jquery')

//Höhenanpassungsscript

if(parent.frames.length > 0) {
	var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
	var eventer = window[eventMethod];
	var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
	var thisSiteHeight = $('body').height() + 0;
	var id, eventSource, eventOrigin;
	var faqClick = false;

	eventer(messageEvent, function(event) {
		eventSource = event.source;
		eventOrigin = event.origin;

		var data = event.data;
		if(!data || data.length === 0) {
			return;
		}

		data = data.split('-');
		if(data.length !== 2) {
			return;
		}

		if(data[0] !== 'height') {
			return;
		}

		id = parseInt(data[1], 10);
		if(isNaN(id)) {
			return;
		}

		eventSource.postMessage(id + '-' + thisSiteHeight + '-' + 0, eventOrigin);

		function resize(offsetPosition) {
			if(window.self != window.top) {
				var thisSiteHeight = $('body').height() + 60;

				if(offsetPosition !== undefined) {
					eventSource.postMessage(id + '-' + thisSiteHeight + '-' + offsetPosition, eventOrigin);
				}
				else {
					eventSource.postMessage(id + '-' + thisSiteHeight, eventOrigin);
				}
			}

			if(!faqClick && window.location.search.match(/\?token=\w+/i) !== null) {
				document.getElementById('formSection').scrollIntoView();
			}
		}

		if(typeof $.incognito !== 'object') $.incognito = {};
		if(typeof $.incognito.hoehenanpassung !== 'object') $.incognito.hoehenanpassung = {};
		$.incognito.hoehenanpassung.resize = resize;

		$(document).on('groesseGeaendert', function(event, offsetPosition) {
			setTimeout(function(){ resize(offsetPosition); }, 100);
		});

		$(window).resize(function() {
			setTimeout(function(){ resize(); }, 200);
		});

		$(document).ready(function() {
			setTimeout(function(){ resize(); }, 200);
		});

      $(".accordion__button").click(function () {
         setTimeout(function () {
            resize();
         }, 200);
      });

      // $("[name='anmeldung_submit']").click(function () {
      //    setTimeout(function () {
      //       window.parent.postMessage({
      //          type: 'scroll',
      //          params: {
      //             scrollTo: $('#registrierung').offset().top
      //          }
      //       }, '*');
      //    }, 500)
      // });
      
      $('#btn-registrierung').click(function (event) {
         event.preventDefault();

         var target = $($(this).attr('href'));
         var targetOffset = target.offset().top;

         window.parent.postMessage({
            type: 'scroll',
            params: {
               scrollTo: targetOffset
            }
         }, '*');
      });

	}, false);
}